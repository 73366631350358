<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="工厂简称">
                <a-input v-model="queryParam.name"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户名称">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable } from '@/components'
import { getMetaInfoPage, deleteFactoryMetaTypeInfo } from '@/api/wms/meta'
import { getCustomer } from '@/api/common'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '工厂简称',
          dataIndex: 'name'
        },
        {
          title: '简码',
          dataIndex: 'abbr',
          sorter: true
        },
        {
          title: '客户简称',
          dataIndex: 'customer_name'
        },
        {
          title: '所在地区',
          dataIndex: 'district_name'
        },
        {
          title: '所在地点',
          dataIndex: 'location_name'
        },
        {
          title: '工厂地址',
          dataIndex: 'address'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      currentData: null,
      selectedRowKeys: [],
      loadData: parameter => {
        // if (!parameter.orderby) {
        //   parameter.order = 'desc'
        //   parameter.orderby = 'created_at'
        // }
        return getMetaInfoPage('factory', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'Factory' && this.queryParam) {
        newRoute.meta.title = '工厂信息'
        if (newRoute.query.isEdit) {
          Object.assign(this.currentData, newRoute.params)
        } else if (newRoute.query.isEdit === false) {
          this.$refs.table.refresh()
        }
      }
    }
  },
  created() {
    // getCustomerList().then(v => {
    //   this.customerOps = v
    // })
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: 'FactoryCur',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record, index) {
      // this.$router.push({
      //   path: '/wms/meta/factory/' + record.id
      // })
      this.currentData = record
      this.$router.push({
        name: 'FactoryCur',
        params: record
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['customer_id'] = value.key
      this.customerOps = []
      this.fetching = false
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteFactoryMetaTypeInfo('factory', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
